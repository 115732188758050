import React, { useRef, useEffect } from 'react';

export const TypedText = ({ textContent, id, backgroundColor, textColor, onClick, fontFamily, selected }) => {
    const canvasRef = useRef(null);
    const letterAppearanceDelay = 50;
    const delayTime = 150;
    const mediaRecorderRef = useRef(null);
    const isRecordingRef = useRef(false);

    useEffect(() => {
        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d');

        // Text to animate
        let currentIndex = 0;

        const drawText = () => {
            // Clear the canvas
            ctx.clearRect(0, 0, canvas.width, canvas.height);
        
            ctx.fillStyle = backgroundColor;
            ctx.fillRect(0, 0, canvas.width, canvas.height);
        
            // Draw the text up to the current index
            ctx.font = '40px Montserrat';
            ctx.fillStyle = textColor;
            ctx.textAlign = 'center';
            ctx.textBaseline = 'middle';
            const centerX = canvas.width / 2;
            const centerY = canvas.height / 2;
            ctx.fillText(textContent.slice(0, currentIndex), centerX, centerY);
        
            // Increment the index
            currentIndex++;
        
            // Check if we've reached the end of the text
            if (currentIndex <= textContent.length) {
                setTimeout(() => {
                    requestAnimationFrame(drawText);
                }, letterAppearanceDelay);
            } else {
                // Animation complete, start the delay
                setTimeout(() => {
                    // Draw an invisible change to the canvas to keep the stream active
                    ctx.fillStyle = 'rgba(0, 0, 0, 0)'; // Transparent black
                    ctx.fillRect(0, 0, canvas.width, canvas.height);
        
                    // Stop the recording after the desired delay
                    setTimeout(() => {
                        mediaRecorderRef.current.stop();
                        isRecordingRef.current = false;
                    }, delayTime); // Adjust the delayTime as needed
                }, delayTime); // Adjust the delayTime as needed
            }
        };

        if (!isRecordingRef.current) {
            // Start recording when the animation begins
            const stream = canvas.captureStream(30); // 30 is the framerate
            const options = { mimeType: 'video/webm;codecs=vp9', bitsPerSecond: 100000 };
            const mediaRecorder = new MediaRecorder(stream, options);
            mediaRecorderRef.current = mediaRecorder;

            mediaRecorder.start();
            isRecordingRef.current = true;
        }

        drawText();

    }, [textContent, textColor, backgroundColor, fontFamily, id ]);

    return (
        <canvas id={id} width="600" height="600" ref={canvasRef}
            onClick={onClick}
            style={{
                display: selected === 0 ? 'none' : 'flex',
            }}></canvas>
    );
}