export default function Button({text, destination}) {
    if (typeof destination !== 'string') {
        console.error('Button component expects a "destination" prop of type string.');
        return null; // or some fallback UI
    }

    return(
        <button
                type="button"
                className="rounded-xl bg-rose-600 px-3 py-2 my-2 text-sm font-medium text-white shadow-sm hover:bg-rose-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-rose-600"
                >
            <a href={destination}>{text}</a>
        </button>
    )
}