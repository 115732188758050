import { useState, useRef, useEffect } from "react"
import { TypedText } from "../components/AnimatedCanvas"
import axios from "axios"
import Modal from "./Modal"
import { AddCircle } from "@mui/icons-material"
import LoadingSpinner from "./LoadingSpinner"
import DeleteAlertDialog from "./AlertDialog"
import supabase from "../lib/supabaseClient"
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

export default function Editor() {
    //const selectedFont = "Inter";
    let videoId = useParams().videoId;
    let navigate = useNavigate()
    const animationOptions = ['None','Type out','Zoom in'];
    const [frameData, setFrameData] = useState([]);
    const [videoData, setVideoData] = useState({videoId: null, videoTitle: '', audio: 'None'});

    const fileInputRef = useRef();
    const [selectedFrame, setSelectedFrame] = useState(null);
    const [formData, setFormData] = useState({
        frameDuration: frameData[selectedFrame]?.frameDuration,
        textColor: frameData[selectedFrame]?.textColor,
        content: frameData[selectedFrame]?.content,
        animation: frameData[selectedFrame]?.animation,
    })

    const [backgroundColors, setBackgroundColors] = useState(['#e11d47','#eb71af','hsl(15,84%,54%)','#ffffff','#eff3f4']);
    const [textColors, setTextColors] = useState(['#ffffff','#000000','hsl(15,84%,54%)','#e11d47','#eb71af','#9ba3af']);
    const [isModalOpen, setModalOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const handleOpenModal = () => {
        setModalOpen(true);
        console.log(videoData)
    };

    const handleCloseModal = () => {
        setModalOpen(false);
    };

    const handleSelectOption = async (option) => {
        setVideoData({...videoData, audio: option});

        const { error: deleteVideoError } = await supabase
            .from('videos')
            .update({audio: option})
            .eq('videoId', videoData.videoId)

        setModalOpen(false);
    }

    async function getInitialData() {
        if (videoId === 'new') {
            const { data: { user } } = await supabase.auth.getUser()
            let userId = user.id

            const { data: dbVideoData } = await supabase
                .from('videos')
                .insert({userId: userId, videoTitle: 'New Video', audio: 'None'})
                .select()
            
            console.log(videoData)

            videoId = dbVideoData[0].videoId
            setVideoData({...videoData, videoId: videoId, videoTitle: 'New Video'})

            const {data: frameData } = await supabase
            .from('frames')
            .insert({ 
                    frameSequence: 100,     
                    content: "New frame", 
                    backgroundColor: '#FFFFFF', 
                    frameDuration: 1, 
                    textColor: '#000000', 
                    imageUrl: '', 
                    animation: 'None',
                    videoId: videoId,
                })
            .select()
            setSelectedFrame(100)

            return frameData
            
        } else {
            const { data: dbVideoData } = await supabase
                .from('videos')
                .select()
                .eq('videoId', videoId)

            const { data: frameData } = await supabase
                .from('frames')
                .select()
                .eq('videoId', videoId)
                .order('frameSequence')

            setSelectedFrame(100)

            setVideoData({...videoData, videoId: videoId, videoTitle: dbVideoData[0].videoTitle, audio: dbVideoData[0].audio})
        return frameData
        }
    }

    // Load initial data
    useEffect(() => {
        getInitialData().then((data => {
            setFrameData(data);
            console.log(data);
        }));
    },[])

    // Switch selected frame & load menu content
    useEffect(() => {
        setFormData({
            content: frameData.find(frame => frame.frameSequence === selectedFrame)?.content,
            textColor: frameData.find(frame => frame.frameSequence === selectedFrame)?.textColor,
            frameDuration: frameData.find(frame => frame.frameSequence === selectedFrame)?.frameDuration,
            animation: frameData.find(frame => frame.frameSequence === selectedFrame)?.animation,
        });
    }, [selectedFrame, frameData]);

    // Delete frames
    useEffect(() => {
        const handleDeletePress = async (event) => {
            if ((event.key === 'Delete' || event.key === 'Backspace') && selectedFrame !== null && document.activeElement.tagName !== 'INPUT') {
                const {error: deleteError } = await supabase
                    .from('frames')
                    .delete()
                    .eq('frameSequence', selectedFrame)
                

                setFrameData(frameData => {
                    const newFrames = frameData.filter(frame => frame.frameSequence !== selectedFrame);
                    if (newFrames.length === 0) {
                        setSelectedFrame(null); // No frames left, reset selectedFrame
                    } else if (newFrames.length <= selectedFrame) {
                        setSelectedFrame(newFrames.length * 100); // Select the last frame if the deleted one was the last in the list
                    }
                    return newFrames;
                });
            }
        };
    
        document.addEventListener('keydown', handleDeletePress);
    
        return () => {
            document.removeEventListener('keydown', handleDeletePress);
        };
    }, [selectedFrame]);

    async function deleteVideo() {
        const { error: deleteFramesError } = await supabase
            .from('frames')
            .delete()
            .eq('videoId', videoData.videoId,);
        
        const { error: deleteVideoError } = await supabase
            .from('videos')
            .delete()
            .eq('videoId', videoData.videoId,);
        
        navigate('/dashboard')
    }

    const updateColorSelection = (newColor) => {
        setBackgroundColors(backgroundColors => {
            const newColors = [newColor, ...backgroundColors.slice(0, -1)];
            return newColors;
        })   
    }

    const updateBackgroundColor = async (id, newColor) => {
        setFrameData(prevState => prevState.map(frame => 
            frame.frameSequence === id ? { ...frame, backgroundColor: newColor } : frame
        ));

        const { error: bgColorError } = await supabase
            .from('frames')
            .update({ backgroundColor: newColor})
            .eq('videoId', videoData.videoId,)
            .eq('frameSequence', id)
    }

    const updateTextContent = async (id, newContent) => {
        setFrameData(prevState => prevState.map(frame => 
            frame.frameSequence === id ? { ...frame, content: newContent } : frame
        ));
        
        const { error: contentError } = await supabase
            .from('frames')
            .update({ content: newContent})
            .eq('videoId', videoData.videoId,)
            .eq('frameSequence', id)
    }

    const updateTextColor = async (id, newColor) => {
        setFrameData(prevState => prevState.map(frame => 
            frame.frameSequence === id ? { ...frame, textColor: newColor } : frame
        ));

        const { error: textColorError } = await supabase
            .from('frames')
            .update({ textColor: newColor})
            .eq('videoId', videoData.videoId,)
            .eq('frameSequence', id)
    }

    const updateFrameDuration = async (id, newDuration) => {
        setFrameData(prevState => prevState.map(frame => 
            frame.frameSequence === id ? { ...frame, frameDuration: newDuration } : frame
        ));

        const { error: frameDurationError } = await supabase
            .from('frames')
            .update({ frameDuration: newDuration})
            .eq('videoId', videoData.videoId,)
            .eq('frameSequence', id)
    }

    const updateAnimation = async (id, newAnimation) => {
        setFrameData(prevState => prevState.map(frame => 
            frame.frameSequence === id ? { ...frame, animation: newAnimation } : frame
        ));
        
        const { error: animationError } = await supabase
            .from('frames')
            .update({ animation: newAnimation})
            .eq('videoId', videoData.videoId,)
            .eq('frameSequence', id)
    }

    const addFrame = async () => {
        const maxFrameSequence = frameData.reduce((max, obj) => {
            return obj.frameSequence > max ? obj.frameSequence : max;
          }, frameData[0].frameSequence);


        setFrameData(prevState => [...prevState,
            {frameSequence: maxFrameSequence+100, content: "New frame", backgroundColor: '#FFFFFF', frameDuration: 1, textColor: '#000000', imageUrl: '', animation: 'None'},
        ] );

        setSelectedFrame(maxFrameSequence+100)

        const {error: insertError } = await supabase
        .from('frames')
        .insert({ 
                frameSequence: maxFrameSequence+100,     
                content: "New frame", 
                backgroundColor: '#FFFFFF', 
                frameDuration: 1, 
                textColor: '#000000', 
                imageUrl: '', 
                animation: 'None',
                videoId: videoData.videoId,
            })
    }

    async function handleFileUpload(id, event){
        console.log('File upload function started')
        const file = event.target.files[0];
        let imageUrl = ''; 
        const { data: { user } } = await supabase.auth.getUser()
        let userId = user.id

        if (file) {
            const fileExt = file.name.split('.').pop();
            const fileName = `${Math.random()}.${fileExt}`;
            imageUrl = userId + '/' + fileName
            console.log(imageUrl)
            
            const { data, error } = await supabase.storage
                .from('frameImages')
                .upload(imageUrl, file);
            
            //setUploadSuccess(true);

            console.log("The data object is: ")
            console.log(data)

            if (error) {
                throw error;
            }

            imageUrl = supabase.storage.from('frameImages').getPublicUrl(imageUrl).data.publicUrl

            const { imageUpdateError } = await supabase
                .from('frames')
                .update({ imageUrl: imageUrl})
                .eq('videoId', videoData.videoId)
                .eq('frameSequence', selectedFrame)
            
            if (imageUpdateError) {
                throw error
            }

            setFrameData(prevState => prevState.map(frame => 
                frame.frameSequence === id ? { ...frame, imageUrl: imageUrl } : frame
            ));
            
            console.log(frameData)
        }
      };

    const handleExportToVideo = async () => {    
        try {
            console.log("Download function started")

            setIsLoading(true)
            const response = await axios.post('/api/create-video', {video: videoData, frames: frameData});
            
            const videoUrl = response.data;
            const downloadLink = document.createElement('a');
            downloadLink.href = videoUrl;
            downloadLink.download = 'exported_video.mp4'; // Suggest a filename for the download

            setIsLoading(false)
            document.body.appendChild(downloadLink);
            downloadLink.click();
            console.log(downloadLink)

            // Clean up by removing the link and revoking the object URL
            document.body.removeChild(downloadLink);
            URL.revokeObjectURL(videoUrl);
    
            console.log('Video creation response:', videoUrl);
            
            
        } catch (error) {
            console.error('Error exporting to video:', error);
        }
    };

    return(
        <div className="flex flex-col">
            <div className="flex flex-row grow-0 h-12 w-full bg-white">
                <div className="flex flex-row basis-1 justify-start items-center ml-4 mr-auto gap-5"><a href='/dashboard' className="text-lg font-bold">Storycuts</a></div>
                <div className="flex flex-row basis-1 justify-center items-center pl-24 gap-3">
                        <p className="text-nowrap">{videoData.videoTitle}</p>
                        <DeleteAlertDialog onClick={deleteVideo} />
                </div>
                <div className="flex flex-row basis-1 justify-end items-center ml-auto mr-8 gap-3">
                        <button type="button"
                            className="rounded-xl bg-rose-600 px-3 py-2 my-2 text-sm font-medium text-white shadow-sm hover:bg-rose-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-rose-600"
                            onClick={handleOpenModal}>
                                Audio
                            <input id="file-upload" name="file-upload" type="file" className="sr-only focus:border-none" />
                        </button>
                        <button type="button" className="flex flex-row items-center rounded-xl bg-rose-600 px-3 py-2 my-2 text-sm font-medium text-white shadow-sm hover:bg-rose-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-rose-600"
                            onClick={handleExportToVideo}>
                                {isLoading ? <LoadingSpinner /> : null}
                                Download
                        </button>
                </div>
            </div>
            <div className="flex flex-grow w-full">
                <div className="flex flex-row w-full" style={{ height: 'calc(100vh - 3rem)' }}>
                    <Modal
                        isOpen={isModalOpen}
                        onClose={handleCloseModal}
                        options={['None', 'Inspirational']}
                        onSelect={handleSelectOption}
                    />
                    <div className="block mx-auto pt-3 w-72 bg-gray-50 overflow-y-scroll">
                        {frameData.map((frame) => (
                            <div className="flex flex-row" key={frame.frameSequence}>
                                <div className="flex-grow"></div>
                                <div className="flex flex-col justify-center items-center w-28 h-28 my-2" 
                                    style={{ 
                                            backgroundColor:frame.backgroundColor, 
                                            borderRadius: '5%',
                                            ringOffsetWidth: frame.frameSequence === selectedFrame ? 4 : 0,
                                            ringWidth: frame.frameSequence === selectedFrame ? 2 : 1, // 4 is an example value for the ring width
                                            ringColor: frame.frameSequence === selectedFrame ? '#6b7280' : '#6b7280', // #34D399 is an example value for the ring color
                                            boxShadow: frame.frameSequence === selectedFrame ? '0 0 0 4px #6b7280' : '0 0 0 1px #6b7280' // This creates the offsetted ring effect
                                        }}
                                    onClick={() => setSelectedFrame(frame.frameSequence)}>
                                    <p className="align-middle text-center"
                                    style={{ color: frame.textColor, fontFamily: 'Montserrat',fontSize: "10px" }}
                                    >{frame.content}</p>
                                    {frame.imageUrl !== '' && frame.imageUrl !== null ?
                                        <img src={frame.imageUrl} alt={'Frame content'} width={75} /> 
                                    : null} 
                                </div>
                                <div className="flex-grow"></div>
                            </div>
                        ))}
                        <div className="flex flex-row justify-center">
                            <AddCircle color="action" fontSize={"large"} onClick={addFrame} />
                        </div>
                    </div>
                    <div className="flex flex-col flex-grow justify-start items-center bg-gray-50 overflow-y-scroll">
                        <div className="flex-grow bg-gray-50"></div>
                        {frameData.map((frame) => (
                            frame.animation === 'Type out' ? 
                             <TypedText 
                                key={frame.frameSequence} 
                                textContent={frame.content}
                                id={frame.frameSequence}
                                backgroundColor={frame.backgroundColor}
                                textColor={frame.textColor}
                                fontFamily={"'Montserrat'"}
                                className="flex shrink-0 justify-center items-center w-48 h-48 m-3 rounded-sm frame-canvas"
                                selected={frame.frameSequence === selectedFrame ? 1 : 0 }
                                />:
                            <div 
                                key={frame.frameSequence} 
                                id={frame.frameSequence} 
                                style={{
                                        backgroundColor: frame.backgroundColor,
                                        color: frame.textColor,
                                        fontFamily: 'Montserrat',
                                        height: 600,
                                        width: 600,
                                        fontSize: 40,
                                        display: frame.frameSequence !== selectedFrame ? 'none' : 'flex'
                                    }}
                                className="flex shrink-0 justify-center items-center m-3 p-5 rounded-sm frame-canvas"
                                > 
                                {frame.content}
                                {frame.imageUrl !== '' && frame.imageUrl !== null ?
                                 <img src={frame.imageUrl} alt={'Frame content'} width={300} /> 
                                 : null}
                                </div>
                        ))}
                        <div className="flex-grow bg-gray-50"></div>
                    </div>
                    <div className="w-72 bg-gray-50" style={{ height: 'calc(100vh - 3rem)' }}>
                        <form className="flex flex-col mx-5 mt-3">
                            <div className="m-2">
                                <label className="font-medium">Background Color</label>
                                {/*<input type="text" value={formData.backgroundColor} onChange={(e) => updateBackgroundColor(selectedFrame, e.target.value)}></input>
                                */}
                                <div className="flex flex-row justify-center gap-2 my-2">
                                    <div className="h-10 w-10 border border-solid rounded-md" style={{backgroundColor: backgroundColors[0]}} onClick={(e) => updateBackgroundColor(selectedFrame, backgroundColors[0])}></div>
                                    <div className="h-10 w-10 border border-solid rounded-md" style={{backgroundColor: backgroundColors[1]}} onClick={(e) => updateBackgroundColor(selectedFrame, backgroundColors[1])}></div>
                                    <div className="h-10 w-10 border border-solid rounded-md" style={{backgroundColor: backgroundColors[2]}} onClick={(e) => updateBackgroundColor(selectedFrame, backgroundColors[2])}></div>
                                </div>
                                <div className="flex flex-row justify-center gap-2 my-2">
                                    <div className="h-10 w-10 border border-solid rounded-md" style={{backgroundColor: backgroundColors[3]}} onClick={(e) => updateBackgroundColor(selectedFrame, backgroundColors[3])}></div>
                                    <div className="h-10 w-10 border border-solid rounded-md" style={{backgroundColor: backgroundColors[4]}} onClick={(e) => updateBackgroundColor(selectedFrame, backgroundColors[4])}></div>
                                    <div className="flex justify-center items-center h-10 w-10 rounded-sm bg-white" onClick={(e) => updateColorSelection('#fff')}>+</div>
                                </div>
                            </div>
                            <div className="m-2">
                                <label className="m-2 font-medium">Text Content</label>
                                <input type="text" value={formData.content} onChange={(e) => updateTextContent(selectedFrame, e.target.value)}></input>
                            </div>
                            <div className="m-2">
                                <label className="m-2 font-medium">Text Color</label>
                                <div className="flex flex-row justify-center gap-2 my-2">
                                    <div className="h-10 w-10 border border-solid rounded-md" style={{backgroundColor: textColors[0]}} onClick={(e) => updateTextColor(selectedFrame, textColors[0])}></div>
                                    <div className="h-10 w-10 border border-solid rounded-md" style={{backgroundColor: textColors[1]}} onClick={(e) => updateTextColor(selectedFrame, textColors[1])}></div>
                                    <div className="h-10 w-10 border border-solid rounded-md" style={{backgroundColor: textColors[2]}} onClick={(e) => updateTextColor(selectedFrame, textColors[2])}></div>
                                </div>
                                <div className="flex flex-row justify-center gap-2 my-2">
                                    <div className="h-10 w-10 border border-solid rounded-md" style={{backgroundColor: textColors[3]}} onClick={(e) => updateTextColor(selectedFrame, textColors[3])}></div>
                                    <div className="h-10 w-10 border border-solid rounded-md" style={{backgroundColor: textColors[4]}} onClick={(e) => updateTextColor(selectedFrame, textColors[4])}></div>
                                    <div className="h-10 w-10 border border-solid rounded-md" style={{backgroundColor: textColors[5]}} onClick={(e) => updateTextColor(selectedFrame, textColors[5])}></div>
                                </div>
                                {/*<input type="text" value={formData.textColor} onChange={(e) => updateTextColor(selectedFrame, e.target.value)}></input>*/}
                            </div>
                            <div className="m-2">
                                <label className="m-2 font-medium">Frame Duration</label>
                                <input type="text" value={formData.frameDuration} onChange={(e) => updateFrameDuration(selectedFrame, e.target.value)}></input>
                            </div>
                            <div className="m-2">
                                <label className="m-2 font-medium">Add Image</label>
                                <div className="mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10">
                                    <div className="text-center">
                                        <div className="flex flex-col text-sm text-center leading-6 text-gray-600">
                                            <label htmlFor="file-upload" className="relative cursor-pointer rounded-md bg-transparent font-semibold text-rose-600 focus-within:outline-none hover:text-rose-500">
                                            <p className="text-gray-600 text-4xl bg-transparent">+</p>
                                            <input id="file-upload" name="file-upload" type="file" className="sr-only focus:border-none" 
                                            ref={fileInputRef} onChange={(e) => handleFileUpload(selectedFrame, e)}/>
                                            </label>
                                            {/*<p className="pl-1">or drag and drop</p>*/}
                                        </div>
                                    </div> 
                                </div>
                            </div>
                            <div className="m-2">
                                <label className="m-2 font-medium">Animation</label>
                                <div className="flex flex-row mt-2 justify-center gap-2">
                                {animationOptions.map((animation) => (
                                    <div className="h-10 w-10 p-2 rounded-sm bg-white text-xs flex flex-col justify-center items-center text-center" 
                                        style={{ 
                                            borderRadius: '5%',
                                            ringOffsetWidth: formData.animation === animation ? 4 : 0,
                                            ringWidth: formData.animation === animation ? 2 : 0, // 4 is an example value for the ring width
                                            ringColor: formData.animation === animation ? '#6b7280' : 'transparent', // #34D399 is an example value for the ring color
                                            boxShadow: formData.animation === animation ? '0 0 0 2px #6b7280' : 'none' // This creates the offsetted ring effect
                                        }}
                                        onClick={(e) => updateAnimation(selectedFrame, animation)} key={animation}>{animation}
                                        </div>
                                )
                                )}
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}