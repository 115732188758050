import React from 'react';
import { Close } from '@mui/icons-material';

function Modal({ isOpen, onClose, options, onSelect }) {
  if (!isOpen) return null;

  return (
    <div style={{ position: 'fixed', top: 0, left: 0, right: 0, bottom: 0, backgroundColor: 'rgba(0, 0, 0, 0.5)' }}>
      <div className='relative rounded-md' style={{ maxWidth: '300px', margin: '20% auto', background: 'white', padding: '20px' }}>
        <ul>
          {options.map((option, index) => (
            <li key={index} onClick={() => onSelect(option)} style={{ cursor: 'pointer' }}>
              {option}
            </li>
          ))}
        </ul>
        <Close className='absolute top-2 right-2' color='action' onClick={onClose}/>
      </div>
    </div>
  );
}

export default Modal;
