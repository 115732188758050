import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Editor from './components/Editor';
import Dashboard from './components/Dashboard';
import ErrorPage from './components/ErrorPage';
import LoginPage from './components/Login';
import SignUpPage from './components/Signup';
import PrivacyPolicy from './components/PrivacyPolicy';
import TermsOfService from './components/TermsOfService';
import LandingPage from './components/LandingPage';
import { useState, useEffect } from 'react'
import supabase from './lib/supabaseClient';

export default function App() {
  const [session, setSession] = useState(null)

  useEffect(() => {
    supabase.auth.getSession().then(({ data: { session } }) => {
      setSession(session)
    })})

  return (
    <div className="App">
      <Router>
        <Routes>
          <Route exact path="/dashboard" element={<Dashboard />} />
          <Route path="/videos/:videoId" element={<Editor />} />
          <Route exact path="/login" element={<LoginPage />} />
          <Route exact path="/signup" element={<SignUpPage />} />
          <Route exact path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route exact path="/terms" element={<TermsOfService />} />
          <Route exact path="/" element={<LandingPage />} />
          <Route path="*" element={<ErrorPage />} />
        </Routes>
      </Router>
    </div>
  );
}


