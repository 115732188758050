import React from 'react';

function LandingPage() {
  return (
    <div className="bg-white">
      {/* Header */}
      <header className="bg-white py-6 shadow w-full">
        <div className="container mx-auto flex justify-between items-center px-4">
          <div>
            <div className="text-2xl font-bold text-black">
              Storycuts
            </div>
          </div>
          <nav>
            <ul className="flex space-x-6">
              <li>
                <a href="/signup" className="text-gray-700 hover:text-indigo-600">
                  Sign Up
                </a>
              </li>
              <li>
                <a href="/login" className="text-gray-700 hover:text-indigo-600">
                  Login
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </header>

      {/* Hero Section */}
      <section className="bg-white text-black py-20">
        <div className="container mx-auto px-4">
          <h1 className="text-4xl font-bold mb-4">
            Build industry standard videos without professional help
          </h1>
          <p className="text-xl mb-8">
            Storycuts makes it easy to build text-based videos to tell your story
          </p>
          <a href='/signup'>
            <button className="bg-rose-600 text-white py-3 px-6 rounded-md font-semibold hover:bg-rose-700 hover:text-white transition duration-300">
              Get Started
            </button>
          </a>
        </div>
      </section>

      {/* Features Section */}
      {/* 
      <section className="py-20">
        <div className="container mx-auto px-4">
          <h2 className="text-4xl font-bold mb-8 text-center">
            Placeholder Features Section
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            <div className="bg-white p-6 rounded-lg shadow">
              <h3 className="text-xl font-bold mb-4">Feature 1</h3>
              <p>Placeholder description for Feature 1.</p>
            </div>
            <div className="bg-white p-6 rounded-lg shadow">
              <h3 className="text-xl font-bold mb-4">Feature 2</h3>
              <p>Placeholder description for Feature 2.</p>
            </div>
            <div className="bg-white p-6 rounded-lg shadow">
              <h3 className="text-xl font-bold mb-4">Feature 3</h3>
              <p>Placeholder description for Feature 3.</p>
            </div>
          </div>
        </div>
      </section>
       */}

      <section className='flex flex-col justify-center w-[60rem] mx-auto my-10 bg-white h-64'>
        <div className='flex flex-row p-5 max-w-5xl'>
          <div className='relative basis-1/2 max-w-1/2 pl-36'>
              <div className='absolute h-48 w-48 top-0 left-24 bg-white z-10 border border-solid rounded-lg'></div>
              <div className='absolute h-48 w-48 top-8 left-16 bg-rose-600 z-0 rounded-lg'></div>
          </div>
          <div className='flex flex-col basis-2/5 max-w-2/5 justify-center items-start p-5'>
            <div className='text-3xl font-bold text-left text-black'>Build videos in minutes</div>
            <br></br>
            <div className='text-md text-left text-black'>Create a 1st version or a final cut quickly & easily.</div>
          </div>
          <div className='basis-1/10 max-w-1/10'></div>
        </div>
      </section>

      <section className='flex flex-col justify-center w-[60rem] mx-auto my-10 bg-white h-64'>
        <div className='flex flex-row-reverse p-5 max-w-5xl'>
          <div className='relative basis-1/2 max-w-1/2 pl-36'>
            <div className='absolute h-48 w-48 top-0 left-24 bg-white z-10 border border-solid rounded-lg'></div>
            <div className='absolute h-48 w-48 top-8 left-16 bg-rose-600 z-0 rounded-lg'></div>
          </div>
          <div className='flex flex-col basis-2/5 max-w-2/5 justify-center items-start p-5'>
            <div className='text-3xl font-bold text-left text-black'>Professional-level animations</div>
            <br></br>
            <div className='text-md text-left text-black'>Amp up the energy in your video with custom animations like typing out each letter.</div>
          </div>
          <div className='basis-1/10 max-w-1/10'></div>
        </div>
      </section>

      <section className='flex flex-col justify-center w-[60rem] mx-auto my-10 bg-white h-64'>
        <div className='flex flex-row p-5 max-w-5xl'>
          <div className='relative basis-1/2 max-w-1/2 pl-36'>
            <div className='absolute h-48 w-48 top-0 left-24 bg-white z-10 border border-solid rounded-lg'></div>
              <div className='absolute h-48 w-48 top-8 left-16 bg-rose-600 z-0 rounded-lg'></div>
          </div>
          <div className='flex flex-col basis-2/5 max-w-2/5 justify-center items-start p-5'>
            <div className='text-3xl font-bold text-left text-black'>Add the right music easily</div>
            <br></br>
            <div className='text-md text-left text-black'>Choose from a range of pre-set music options to make sure your video hits the right emotional note.</div>
          </div>
          <div className='basis-1/10 max-w-1/10'></div>
        </div>
      </section>


      {/* Footer */}
      <footer className="bg-gray-900 text-white py-10 w-full">
        <div className="container mx-auto px-4">
          <div className="flex justify-between items-center">
            <div className="text-2xl font-bold">
                Storycuts
            </div>
            <nav>
              <ul className="flex space-x-6">
                <li>
                  <a href="/terms" className="hover:text-indigo-400">
                    Terms
                  </a>
                </li>
                <li>
                  <a href="/privacy-policy" className="hover:text-indigo-400">
                    Privacy
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default LandingPage;