import Button from "./Button"

export default function EmptyState() {
    return(
        <div className="flex flex-col border-2 h-dvh border-dashed rounded-lg">
            <div className="flex-grow"></div>
            <div className="my-auto justify-center items-center ">
                <p className="text-lg font-medium">Create your first video</p>
                <Button text='Get started' destination='/videos/new' />
            </div>
            <div className="flex-grow"></div>
        </div>
    )
}