import React, { useState } from 'react';
import supabase from '../lib/supabaseClient';
import { useNavigate } from 'react-router-dom';

const SignUpPage = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [signupComplete, setSignupComplete] = useState(false);
    const timestamp = Date.now();
    const currentDate = new Date(timestamp);
    let navigate = useNavigate()

    const handleSignUp = async (e) => {
        e.preventDefault();
        setSignupComplete(true);

        const { data, error } = await supabase.auth.signUp({
            email,
            password,
            options: {
                emailRedirectTo: `localhost:3000/auth/callback`, // Originally `${location.origin}/auth/callback`,
              },
        });

        if (error) console.error('Error signing up:', error);

        if (data) {
            const { data: newUser, error: newUserError } = await supabase
            .from('users')
            .insert( 
                {userId: data.user.id, 
                email: email,
                updatedAt: currentDate 
                })
        
            if (newUserError) console.error('Error creating user:', error);

            navigate('/dashboard');

            /*
            const { data: newSubscription, error: newSubscriptionError } = await supabase
            .from('UserSubscriptions')
            .insert( 
                {userId: data.user.id, 
                planId: 2,
                status: 'Active',
                subscriptionStartDate: currentDate,
                subscriptionEndDate: '2099-12-31',
                updatedAt: currentDate 
                }
            )
        
            if (newSubscriptionError) console.error('Error creating subscription:', error);
            */
        }

    };

    return (
        <div className="flex flex-row justify-center h-screen w-full bg-gray-100">
            <div className="flex flex-col justify-center bg-white w-[600px] h-full px-16 grow-0">
                {!signupComplete ? (
                    <div className='flex flex-col justify-center bg-white grow-0'>
                        <h1 className='mb-4 text-center text-lg font-medium'>Create your account</h1>
                        <form onSubmit={handleSignUp} className="flex flex-col">
                            <div className='relative'>
                                <label className="block mb-2 px-1 text-sm font-sans absolute top-[-0.40rem] left-2 bg-white">
                                Email</label>
                                <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} required className="w-full p-2.5 mt-1 mb-5 rounded border border-gray-300" autoComplete="username" />
                            </div>
                            <div className='relative'>
                                <label className="block mb-2 px-1 text-sm font-sans absolute top-[-0.40rem] left-2 bg-white">
                                Password</label>
                                <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} required className="w-full p-2.5 mt-1 mb-5 rounded border border-gray-300" autoComplete="current-password" />
                            </div>
                        <p className='text-xs'>By signing up, you&apos;re agreeing to our <a href="/terms" className='text-blue-600'>Terms of Service</a> and <a href="/privacy-policy" className='text-blue-600'>Privacy Policy</a>.</p><br></br>
                        <input type="submit" value="Sign up" className="w-full p-2.5 bg-black text-white rounded-lg cursor-pointer hover:shadow-lg transition duration-400"/>
                        </form>
                        <div className="flex flex-row text-center mt-5 justify-center">
                            <p>Already have an account? <a href="/login" className="text-blue-600 hover:underline">
                            Login
                            </a></p>
                        </div> 
                    </div> ) : (
                        <div className='flex flex-col justify-center text-center'>
                            <p className='text-lg font-medium'>Loading...</p>
                            {/*<br></br>
                            <p className='text-sm font-normal'>Check your inbox to verify your email</p>*/}
                        </div>
                    )}
            </div>
            <div className='grow bg-rose-50 w-full'>
                
            </div>
        </div>
    );
};

export default SignUpPage;
