import React from 'react';
import * as AlertDialog from '@radix-ui/react-alert-dialog';
import { Delete } from '@mui/icons-material';

function DeleteAlertDialog({onClick}) {
  return (
  <AlertDialog.Root>
    <AlertDialog.Trigger asChild>
      <Delete color="action" />
    </AlertDialog.Trigger>
    <AlertDialog.Portal>
      <AlertDialog.Overlay className="bg-blackA6 data-[state=open]:animate-overlayShow fixed inset-0" />
      <AlertDialog.Content className="data-[state=open]:animate-contentShow fixed top-[50%] left-[50%] max-h-[85vh] w-[90vw] max-w-[500px] translate-x-[-50%] translate-y-[-50%] rounded-[6px] bg-white p-[25px] shadow-[hsl(206_22%_7%_/_35%)_0px_10px_38px_-10px,_hsl(206_22%_7%_/_20%)_0px_10px_20px_-15px] focus:outline-none">
        <AlertDialog.Title className="text-mauve12 m-0 text-[17px] font-medium">Are you sure?</AlertDialog.Title>
        <AlertDialog.Description className="text-gray-700 mt-4 mb-5 text-[15px] leading-normal">
          This action cannot be undone. This will permanently delete your video.
        </AlertDialog.Description>
        <div style={{ display: 'flex', gap: 25, justifyContent: 'flex-end' }}>
          <AlertDialog.Cancel asChild>
            <button className="text-gray-700 bg-gray-200 hover:bg-gray-300 focus:shadow-gray-200 inline-flex h-[35px] items-center justify-center rounded-[4px] px-[15px] font-medium leading-none outline-none border-none focus:shadow-[0_0_0_2px]">Cancel</button>
          </AlertDialog.Cancel>
          <AlertDialog.Action asChild>
            <button className="text-red-700 bg-red-200 hover:bg-red-300 focus:shadow-red-500 inline-flex h-[35px] items-center justify-center rounded-[4px] px-[15px] font-medium leading-none outline-none focus:shadow-[0_0_0_2px]" 
              onClick={onClick}>Yes, delete video
            </button>
          </AlertDialog.Action>
        </div>
      </AlertDialog.Content>
    </AlertDialog.Portal>
  </AlertDialog.Root>
)};

export default DeleteAlertDialog;
