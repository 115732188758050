import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import supabase from '../lib/supabaseClient';

export default function LoginPage() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    let navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();

        console.log(email, password)

        await supabase.auth.signInWithPassword({ 
            email, 
            password,
        });

        navigate('/dashboard');
    };

    return (
        <div className="flex flex-row justify-center h-screen w-full bg-gray-100">
            <div className="flex flex-col justify-center bg-white w-[600px] h-full px-16 grow-0">
                <h1 className='mb-4 text-center text-lg font-medium'>Login to your account</h1>
                <form onSubmit={handleSubmit} className="flex flex-col">
                    <div className='relative'>
                        <label className="block mb-2 px-1 text-sm font-sans absolute top-[-0.40rem] left-2 bg-white">Email</label>
                        <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} required className="w-full p-2.5 mt-1 mb-5 rounded border border-gray-300" autoComplete="username" />
                    </div>
                    <div className='relative'>
                        <label className="block mb-2 px-1 text-sm font-sans absolute top-[-0.40rem] left-2 bg-white">
                        Password</label>
                        <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} required className="w-full p-2.5 mt-1 mb-5 rounded border border-gray-300" autoComplete="current-password" />
                    </div>
                    <input type="submit" value="Login" className="w-full p-2.5 bg-black text-white rounded-lg cursor-pointer hover:shadow-lg transition duration-400"/>
                </form>
                <div className="flex flex-row text-center mt-5 justify-center">
                    <p>Don&apos;t have an account? <a href="/signup" className="text-blue-600 hover:underline">
                    Sign up
                    </a></p>
                </div>
            </div>
            <div className='grow bg-red-50 w-full'>
                
            </div>
        </div>
      
    );
}
